<template>
  <div id="indexThree">
    <div class="header_wrap" v-show="navShow">
      <div class="main">
        <div class="header">
          <div class="top_header" style="z-index: 199999">
            <div style="display: flex; align-items: center; width: 100%; min-height: 60px" :style="[backGroungStyle]">
              <div style="
                  flex: 1;
                  min-height: 60px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <div style="display: flex; align-items: center" @click="drawer = !drawer">
                  <img v-if="brandName == 'CHRISBELLA'"
                    src="https://www.aopiya.com/file/abyFile/backGround/logo-19.png" />
                  <img v-if="brandName == 'SUSEN零售'"
                    src="https://www.aopiya.com/file/abyFile/backGround/logo-21.png" />
                  <img v-if="brandName == 'BAGCO'"
                    src="https://www.aopiya.com/file/abyFile/backGround/logo-20.png" />
                  <div style="
                      width: 0;
                      height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-top: 10px solid white;
                      margin-top: 10px;
                      margin-left: 10px;
                    "></div>
                </div>
              </div>

              <div style="
                  flex: 4;
                  min-height: 75px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <ul style="display: flex; justify-content: space-between; width: 100%">
                  <li>
                    <div style="
                        line-height: 65px;

                        text-align: center;
                        flex: 1;
                      " class="liItem" @mouseover="resetBorderColor()" @click="GoHome">
                      <span v-if="lange == '中文'">首页</span>
                      <span v-else>Front Page</span>
                    </div>
                  </li>
                  <li>
                    <div style="
                        line-height: 65px;

                        text-align: center;
                        width: 100%;
                        flex: 1;
                      " class="liItem" @mouseover="resetBorderColor()" @click="GoGood">
                      <span v-if="lange == '中文'">商城</span>
                      <span v-else>Mall</span>
                    </div>
                  </li>
                  <li @mouseover="changeColor()">
                    <div style="
                        line-height: 65px;

                        text-align: center;
                        width: 100%;
                        flex: 1;
                      " class="liItem">
                      <span v-if="lange == '中文'">导航</span>
                      <span v-else>navigation</span>
                    </div>
                  </li>
                  <li>
                    <div style="
                        line-height: 65px;

                        text-align: center;
                        flex: 2;
                      " class="liItem" @mouseover="resetBorderColor()" @click="buyGood">
                      <span v-if="lange == '中文'">购物车</span>
                      <span v-else>Shopping Cart</span>
                    </div>
                  </li>
                  <li>
                    <div style="
                        line-height: 65px;

                        text-align: center;
                        flex: 2;
                      " class="liItem" @mouseover="resetBorderColor()" @click="buyGoods">
                      <span v-if="lange == '中文'">我的订单</span>
                      <span v-else>My Orders</span>
                    </div>
                  </li>
                  <li>
                    <div style="
                        line-height: 65px;

                        text-align: center;
                        flex: 2;
                      " class="liItem" @mouseover="resetBorderColor()" @click="buyGoodCoCreationCenter">
                      <span v-if="lange == '中文'">共创中心</span>
                      <span v-else>Creation Center </span>
                    </div>
                  </li>
                  <li>
                    <div style="
                        line-height: 65px;

                        text-align: center;
                        flex: 2;
                      " class="liItem" @mouseover="resetBorderColor()" @click="buyGoodRepeatOrder">
                      <span v-if="lange == '中文'">我要翻单</span>
                      <span v-else>Repeat Order</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div style="
                  flex: 0.6;
                  min-height: 65px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                  :background-color="brandName == 'CHRISBELLA'
                    ? '#a5ced0'
                    : brandName == 'SUSEN零售'
                      ? '#000000'
                      : '#ffcccb'
                    " text-color="white" active-text-color="white">
                  <el-submenu index="1">
                    <template slot="title">{{
                      lange == "中文" ? "我的" : "my"
                    }}</template>
                    <el-menu-item index="2-1">{{
                      lange == "中文" ? "退出登录" : "Log Out"
                    }}</el-menu-item>
                    <el-menu-item index="2-2">{{
                      lange == "中文" ? "修改密码" : "Change Password"
                    }}</el-menu-item>
                    <el-menu-item index="2-5">{{
                      lange == "中文" ? "年度任务" : "Annual tasks"
                    }}</el-menu-item>
                    <el-menu-item index="2-6">{{
                      lange == "中文" ? "通知公告" : "Notice Announcement"
                    }}</el-menu-item>
                    <el-menu-item index="2-7">{{
                      lange == "中文" ? "节日日历" : "Festival Calendar"
                    }}</el-menu-item>

                    <el-submenu index="2-3">
                      <template slot="title">{{
                        lange == "中文" ? "营销门店" : "Sales Store"
                      }}</template>
                      <el-menu-item index="2-3-1">{{
                        lange == "中文" ? "广州" : "GuangZhou"
                      }}</el-menu-item>
                      <el-menu-item index="2-3-2">{{
                        lange == "中文" ? "迪拜" : "Dubai"
                      }}</el-menu-item>
                    </el-submenu>
                    <el-submenu index="2-4">
                      <template slot="title">{{
                        lange == "中文" ? "语言切换" : "Change Language"
                      }}</template>
                      <el-menu-item index="2-4-1">中文</el-menu-item>
                      <el-menu-item index="2-4-2">English</el-menu-item>
                    </el-submenu>
                  </el-submenu>
                </el-menu>
                <!-- <div style="margin-left: 35px; width: 65px">
                  <el-dropdown @command="dropdownItemClick">
                    <span
                      class="el-dropdown-link"
                      style="color: white; font-size: 16px"
                      v-if="lange == '中文'"
                    >
                      我的<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <span
                      class="el-dropdown-link"
                      style="color: white; font-size: 16px"
                      v-else
                    >
                      my<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="d" style="margin-top: 19px"
                        ><span v-if="lange == '中文'">退出登陆</span>
                        <span v-else>Log Out</span>
                      </el-dropdown-item>
                      <el-dropdown-item command="e"
                        ><span v-if="lange == '中文'">修改密码</span>
                        <span v-else>Change Password</span>
                      </el-dropdown-item>
                      <el-dropdown-item command="e"
                        ><span v-if="lange == '中文'">营销门店</span>
                        <span v-else>Sales Store</span>
                      </el-dropdown-item>
                      <el-dropdown-item command="e"
                        ><span v-if="lange == '中文'">语言</span>
                        <span v-else>Language</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="
        display: flex;
        align-items: center;
        width: 91%;
        min-height: 220px;
        background: #000000;
        padding: 20px 90px;
        position: fixed;
        top: 75px;
        z-index: 10000;
      " v-if="goodTypeShow" v-for="item in imageList" :key="item.brandName" @mouseleave="resetBorderColor">
      <div style="flex: 1; margin-right: 14px" @click="goToHotGood()">
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-if="lange == '中文'">
          热销商品
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Hot-Sellers
        </div>
        <img v-if="item.menuHotSellImageUrl" :src="item.menuHotSellImage" style="width: 100%" />
        <img v-else src="https://www.aopiya.com/file/abyFile/backGround/Banner-03.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%" />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goToNewGood()">
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-if="lange == '中文'">
          新品预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          New Arrival
        </div>
        <img v-if="item.menuNewCommodityUrl" :src="item.menuNewCommodityImage" style="width: 100%" />
        <img v-else src="https://www.aopiya.com/file/abyFile/backGround/Banner-04.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%" />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goToTUIGood()">
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-if="lange == '中文'">
          首推商品
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Recommended Product
        </div>
        <img v-if="item.menuRecommendImageUrl" :src="item.menuRecommendImage" style="width: 100%" />
        <img v-else src="https://www.aopiya.com/file/abyFile/backGround/CB-TUI1.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%" />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goInPproductionPreSale()">
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-if="lange == '中文'">
          在产预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Pre-Sale In Production
        </div>
        <img v-if="item.menuProductCommodityUrl" :src="item.menuProductCommodityImage" style="width: 100%" />
        <img v-else src="https://www.aopiya.com/file/abyFile/backGround/Banner-05.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%" />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goInTransitPreSale()">
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-if="lange == '中文'">
          在途预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Pre-Sale In Transit
        </div>
        <img v-if="item.menuTransportCommodityUrl" :src="item.menuTransportCommodityImage" style="width: 100%" />
        <img v-else src="https://www.aopiya.com/file/abyFile/backGround/Banner-06.jpg" style="width: 100%" />
      </div>
      <div style="flex: 1; margin-right: 14px">
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-if="lange == '中文'">
          形象店铺
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Standard Store
        </div>
        <img v-if="item.menuStoreImageUrl" :src="item.menuStoreImage" style="width: 100%" />
        <img v-else src="https://www.aopiya.com/file/abyFile/backGround/Banner-07.jpg" style="width: 100%" />
      </div>
      <div style="flex: 1; margin-right: 14px">
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-if="lange == '中文'">
          营销活动
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Campaigns
        </div>
        <img v-if="item.menuMarketingActUrl" :src="item.menuMarketingActImage" style="width: 100%" />
        <img v-else src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg" style="width: 100%" />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goAboutUs()">
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-if="lange == '中文'">
          关于我们
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          About Us
        </div>
        <img v-if="item.aboutUsUrl" :src="item.aboutUsImage" style="width: 100%" />
        <img v-else src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg" style="width: 100%" />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goNews()">
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-if="lange == '中文'">
          新闻
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>New</div>
        <img v-if="item.newsUrl" :src="item.newsImage" style="width: 100%" />
        <img v-else src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg" style="width: 100%" />
      </div>
    </div>
    <!-- <div
      style="
        display: flex;
        align-items: center;
        width: 91%;
        min-height: 220px;
        background: #000000;
        padding: 20px 90px;
        position: fixed;
        top: 75px;
        z-index: 10000;
      "
      v-if="goodTypeShow && brandName == 'CHRISBELLA'"
      @mouseleave="resetBorderColor"
    >
      <div style="flex: 1; margin-right: 14px" @click="goToHotGood()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          热销商品
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Hot-Sellers
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-03.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goToNewGood()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          新品预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          New Arrival
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-04.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goToTUIGood()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          首推商品
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Recommended Product
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/CB-TUI1.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goInPproductionPreSale()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          在产预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Pre-Sale In Production
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-05.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goInTransitPreSale()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          在途预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Pre-Sale In Transit
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-06.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          形象店铺
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Standard Store
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-07.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          营销活动
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Campaigns
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goAboutUs()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          关于我们
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          About Us
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goNews()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          新闻
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>New</div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg"
          style="width: 100%"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        width: 91%;
        min-height: 220px;
        background: #000000;
        padding: 20px 90px;
        position: fixed;
        top: 75px;
        z-index: 10000;
      "
      v-if="goodTypeShow && brandName == 'SUSEN零售'"
      @mouseleave="resetBorderColor"
    >
      <div style="flex: 1; margin-right: 14px" @click="goToHotGood()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          热销商品
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Hot-Sellers
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/SUSEN-navigationBar1-11.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goToNewGood()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          新品预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          New Arrival
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/SUSEN-navigationBar1-12.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goToTUIGood()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          首推商品
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Recommended Product
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/SUSEN-TUI1.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goInPproductionPreSale()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          在产预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Pre-Sale In Production
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/SUSEN-navigationBar1-13.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goInTransitPreSale()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          在途预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Pre-Sale In Transit
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/SUSEN-navigationBar1-14.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          形象店铺
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Standard Store
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/SUSEN-navigationBar1-15.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          营销活动
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Campaigns
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goAboutUs()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          关于我们
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          About Us
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goNews()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          新闻
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>New</div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg"
          style="width: 100%"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        width: 91%;
        min-height: 220px;
        background: #000000;
        padding: 20px 90px;
        position: fixed;
        top: 75px;
        z-index: 10000;
      "
      v-if="goodTypeShow && brandName == 'BAGCO'"
      @mouseleave="resetBorderColor"
    >
      <div style="flex: 1; margin-right: 14px" @click="goToHotGood()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          热销商品
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Hot-Sellers
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/BC-navigationBar1-11.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goToNewGood()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          新品预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          New Arrival
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/BC-navigationBar1-12.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goToTUIGood()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          首推商品
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Recommended Product
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/BC-TUI1.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goInPproductionPreSale()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          在产预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Pre-Sale In Production
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/BC-navigationBar1-13.jpg"
          style="width: 100%; object-fit: cover; object-position: 43% 88%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goInTransitPreSale()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          在途预售
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Pre-Sale In Transit
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/BC-navigationBar1-14.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          形象店铺
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Standard Store
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/BC-navigationBar1-15.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          营销活动
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          Campaigns
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goAboutUs()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          关于我们
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>
          About Us
        </div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg"
          style="width: 100%"
        />
      </div>
      <div style="flex: 1; margin-right: 14px" @click="goNews()">
        <div
          style="color: white; font-size: 14px; margin-bottom: 14px"
          v-if="lange == '中文'"
        >
          新闻
        </div>
        <div style="color: white; font-size: 14px; margin-bottom: 14px" v-else>New</div>
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/Banner-08.jpg"
          style="width: 100%"
        />
      </div>
    </div> -->
    <div class="mbx wrap" style="
        color: #828282;
        line-height: 90px;
        margin-left: 3%;
        font-size: 15px;
        margin-top: 60px;
      " v-if="$route.name != 'GoodsHome'"></div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>

    <div class="footer_wrap" style="
        display: flex;
        justify-content: center;
        height: auto;

        width: 100%;
        flex-direction: column;
      ">
      <img v-if="brandName == 'CHRISBELLA'" src="https://www.aopiya.com/file/abyFile/backGround/fotter1.jpg" />
      <img v-if="brandName == 'SUSEN零售'" src="https://www.aopiya.com/file/abyFile/backGround/sunsenBanner.jpg" />
      <img v-if="brandName == 'BAGCO'" src="https://www.aopiya.com/file/abyFile/backGround/fotter2.jpg" />
    </div>
    <el-dialog title='通知公告' :visible.sync="dialogVisibleNew" width="80%" append-to-body :lock-scroll="false"
      :close-on-click-modal="false">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item :title="item.title" :name="item.title" v-for="(item, index) in Newlist" :key="index">
          <div v-html="item.content"></div>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
    <el-drawer title="" :visible.sync="drawer" direction="ltr" size="15%">
      <div style="height: 91.5vh; background: #c7c7c7">
        <div style="
            width: 100%;
            background: #c7c7c7;
            display: flex;
            align-items: center;
            height: 81px;
            justify-content: center;
          " ref="Chrisbella" @mouseover="handleMouseOver1('Chrisbella')" @mouseleave="handleMouseLeave1('Chrisbella')"
          @click="chageBrand('CHRISBELLA')">
          <div style="display: flex; align-items: center">
            <img src="https://www.aopiya.com/file/abyFile/backGround/logo-19.png" />
          </div>
        </div>
        <div style="
            width: 100%;
            background: #c7c7c7;
            display: flex;
            align-items: center;
            height: 81px;
            justify-content: center;
          " ref="SUSEN" @mouseover="handleMouseOver1('SUSEN零售')" @mouseleave="handleMouseLeave1('SUSEN零售')"
          @click="chageBrand('SUSEN零售')">
          <div style="display: flex; align-items: center">
            <img src="https://www.aopiya.com/file/abyFile/backGround/logo-21.png" />
          </div>
        </div>
        <div style="
            width: 100%;
            background: #c7c7c7;
            display: flex;
            align-items: center;
            height: 81px;
            justify-content: center;
          " ref="BAGCO" @mouseover="handleMouseOver1('BAGCO')" @mouseleave="handleMouseLeave1('BAGCO')"
          @click="chageBrand('BAGCO')">
          <div style="display: flex; align-items: center">
            <img src="https://www.aopiya.com/file/abyFile/backGround/logo-20.png" />
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog v-if="monyth.length != 0" :title="lange == '中文' ? '年度任务' : 'Annual Task'"
      :visible.sync="dialogFormVisibleYear" width="50%">
      <el-table :data="monyth" border style="width: 100%">
        <el-table-column prop="taskyear" label="年份" align="center"> </el-table-column>
        <el-table-column prop="task" label="年度任务" align="center">
          <template slot-scope="scope">
            {{ convertRevenue(scope.row.task) }}
          </template>
        </el-table-column>
        <el-table-column prop="payMoney" label="支付金额" align="center">
          <template slot-scope="scope">
            {{ convertRevenue(scope.row.payMoney) }}
          </template></el-table-column>
      </el-table>
      <div style="margin: 10px;font-size: 16px;font-weight: 600;">营销政策</div>
      <div v-html="monyth[0].marketPolicy" style="width:90%;margin:0 auto"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleYear = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="lange == '中文' ? '节日日历' : 'Annual Task'" :visible.sync="dialogVisibleYear" width="80%">
      <el-tabs v-model="activeName">
        <el-tab-pane label="日历模式" name="day">
          <div class="userManagement-container">

            <el-button type="primary" size="mini" @click="handleSetUp('新增', {})">设置万年历</el-button>

            <div class="flex" style="justify-content: space-between">
              <el-calendar class="lar-el-calendar" :first-day-of-week="7" style="margin-right: 40px; width:100%"
                :date-cell-render="init" v-loading="dayloading">
                <template slot="dateCell" slot-scope="{ date, data }">
                  <div @click="handleDate(data)">
                    <template>
                      {{ data.day }}
                      <div v-for="(item, index) in dayList" :key="index">
                        <div v-if="item.day == data.day" style="color: red; font-size: 20px">
                          ★ <span style="font-size: 14px;color: black;"> {{ item.daytext }}</span>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </el-calendar>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="列表" name="list">
          <el-button type="primary" size="mini" @click="handleSetUp('新增', {})" style="margin-bottom: 10px;">设置万年历</el-button>
          <el-table border :data="listCount" v-loading="dayloading">
            <el-table-column show-overflow-tooltip align="center" label="节日" prop="text"
              min-width="120"></el-table-column>
            <el-table-column show-overflow-tooltip align="center" label="日期" prop="day"
              min-width="100"></el-table-column>
            <el-table-column show-overflow-tooltip align="center" label="备注" prop="remrak"
              min-width="120"></el-table-column>
            <el-table-column show-overflow-tooltip align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="handleSetUp('修改', scope.row)">修改</el-button>
                <el-button type="text" style="color: red" @click="handleDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

    </el-dialog>
    <addDay ref="addDay" @init="init"></addDay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCaptchaImage, getQueryCustomerAnnualTask } from "@/api/other/index.js";
import { getDcShoppingmallHeadimage, getQueryCustomerNotice, getQueryCustomerCalendar,dcMarketingcenterMarketingcalendarDel } from "@/api/good/index.js";
import { getSaveCustomerMsg } from "@/api/auth";
import addDay from "@/components/addDay/index.vue";

export default {
  name: "",
  components: {
    addDay
  },
  data() {
    return {
      isDay: false,
      activeName: "day",
      listDay: [],
      listCount: [],
      listDayList: [],
      dayloading: false,
      elementLoadingText: "正在加载...",
      // 分页
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
      },
      mulSelect: [],
      multipleSelection: [],
      dayList: [],
      dayloading: false,
      Newlist: [],
      drawer: false,
      dialogVisibleNew: false,
      dialogVisibleYear: false,
      activeIndex: "",
      dialogFormVisibleYear: false,
      images: "",
      scrollHeight: 0,
      dialogFormVisible: false,
      dialogFormVisiblep: false,
      isLogin: false,
      goodTypeShow: false,
      articleList: [],
      articleList2: [],
      options: [
        {
          value: "广州",
          label: "广州",
        },
        {
          value: "迪拜",
          label: "迪拜",
        },
      ],
      organizationNameNew: "",
      monyth: [],
      form: {},
      hotNull: 0,
      newNull: 0,
      swiperOptions: {
        loop: true,
        autoplay: true,
        initialSlide: 1,
      },
      organizationNames: localStorage.getItem("organizationNames"),
      navShow: true,
      brandName: this.$route.query.brandName,
      organizationNameListShow: false,
      imageList: [],
      activeNames: [''],
    };
  },
  created() {
    console.log(this.lange);
    console.log(this.organizationName);
    if (this.organizationName == "广州") {
      if (this.lange == "中文") {
        this.organizationNameNew = "广州";
      } else {
        this.organizationNameNew = "Guangzhou";
      }
      this.organizationNameListShow = false;
    } else if (this.organizationName == "迪拜") {
      if (this.lange == "中文") {
        this.organizationNameNew = "迪拜";
      } else {
        this.organizationNameNew = "Dubai";
      }

      this.organizationNameListShow = false;
    } else {
      this.organizationNameListShow = true;
    }

    (this.brandName = this.$route.query.brandName), console.log(this.lange);

    // if (this.organizationName == "迪拜") {
    //   if (this.lange == "中文") {
    //     this.organizationNameNewName = "迪拜";
    //   } else {
    //     this.organizationNameNewName = "Dubai";
    //   }
    // } else {
    //   if (this.lange == "中文") {
    //     this.organizationNameNewName = "广州";
    //   } else {
    //     this.organizationNameNewName = "Guangzhou";
    //   }
    // }

    console.log("this.brandName", this.brandName);
    var inviteCode = this.$route.query.inviteCode;
    if (inviteCode) {
      localStorage.setItem("inviteCode", inviteCode);
    }
    let token = localStorage.getItem("dftToken");
    if (token) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    getDcShoppingmallHeadimage({
      pageSize: 10,
      pageNum: 1,
      brandName: this.brandName,
      organizationId: this.organizationNames == "迪拜" ? 2 : 1,
    }).then((res) => {
      this.imageList = res.data;

      this.imageList.forEach((item) => {
        // 热销商品
        item.menuHotSellImage = this.$locationUrl + "file/" + item.menuHotSellImageUrl;
        // 新品预售
        item.menuNewCommodityImage =
          this.$locationUrl + "file/" + item.menuNewCommodityUrl;
        // 首推商品
        item.menuRecommendImage =
          this.$locationUrl + "file/" + item.menuRecommendImageUrl;
        // 在产预售
        item.menuProductCommodityImage =
          this.$locationUrl + "file/" + item.menuProductCommodityUrl;
        // 在途预售
        item.menuTransportCommodityImage =
          this.$locationUrl + "file/" + item.menuTransportCommodityUrl;
        // 形象店铺
        item.menuStoreImage = this.$locationUrl + "file/" + item.menuStoreImageUrl;
        // 营销活动
        item.menuMarketingActImage =
          this.$locationUrl + "file/" + item.menuMarketingActUrl;
        // 关于我们
        item.aboutUsImage = this.$locationUrl + "file/" + item.aboutUsUrl;
        // 新闻
        item.newsImage = this.$locationUrl + "file/" + item.newsUrl;
      });
    });

    // this.getArticle();
    // this.getArticle2();
    // this.getHotGoodsList();
    this.getAdvertList();
    // this.IsPC()
    getDcShoppingmallHeadimage({
      pageSize: 10,
      pageNum: 1,
      brandName: this.brandName,
      organizationId: this.organizationNames == "迪拜" ? 2 : 1,
    }).then((res) => {
      this.imageList = res.data;

      this.imageList.forEach((item) => {
        // 热销商品
        item.menuHotSellImage = this.$locationUrl + "file/" + item.menuHotSellImageUrl;
        // 新品预售
        item.menuNewCommodityImage =
          this.$locationUrl + "file/" + item.menuNewCommodityUrl;
        // 首推商品
        item.menuRecommendImage =
          this.$locationUrl + "file/" + item.menuRecommendImageUrl;
        // 在产预售
        item.menuProductCommodityImage =
          this.$locationUrl + "file/" + item.menuProductCommodityUrl;
        // 在途预售
        item.menuTransportCommodityImage =
          this.$locationUrl + "file/" + item.menuTransportCommodityUrl;
        // 形象店铺
        item.menuStoreImage = this.$locationUrl + "file/" + item.menuStoreImageUrl;
        // 营销活动
        item.menuMarketingActImage =
          this.$locationUrl + "file/" + item.menuMarketingActUrl;
        // 关于我们
        item.aboutUsImage = this.$locationUrl + "file/" + item.aboutUsUrl;
        // 新闻
        item.newsImage = this.$locationUrl + "file/" + item.newsUrl;
      });
    });
  },
  computed: {

    backGroungStyle() {
      return {
        background:
          this.brandName == "CHRISBELLA"
            ? "#a5ced0"
            : this.brandName == "SUSEN零售"
              ? "#000000"
              : "#ffcccb",
      };
    },
    activeGZ() {
      return {
        background: this.organizationNames == "广州" ? "#ecf5ff" : "#ffffff",
        color: this.organizationNames == "广州" ? "#a5ced0" : "black",
      };
    },
    activeDB() {
      return {
        background: this.organizationNames == "迪拜" ? "#ecf5ff" : "#ffffff",
      };
    },
    ...mapGetters({
      lange: "user/lange",
      organizationName: "user/organizationName",
      priceChangeList: "user/priceChangeList",
    }),
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    console.log(this.priceChangeList);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {},

  methods: {
    handleChange(val) {
      console.log(val);
    },
    convertRevenue(revenue) {
      if (revenue > 10000) {
        // 如果营业额超过1000，将其转换为万元
        return (revenue / 10000).toFixed(2) + ' 万元'; // 保留两位小数
      } else {
        return revenue + ' 元'; // 如果未超过1000，直接返回元
      }
    },
    handleSelect(key, keyPath) {
      console.log(key);

      if (key == "2-1") {
        localStorage.clear();
        sessionStorage.clear();
        window.open(
          this.$locationViewUrl + "#/MallHomepage?brandName=" + this.brandName,
          "_blank"
        );
      }

      if (key == "2-6") {

        getQueryCustomerNotice({
          pageSize: 999,
          pageNum: 1,

        }).then((res) => {
          if (res.code == 200) {
            this.Newlist = res.data.list
            this.dialogVisibleNew = true
          }
        })
      }
      if (key == "2-2") {
        this.$router.push({
          path: "/goods/ChangePwd",
          query: { brandName: this.$route.query.brandName },
        });
      }
      if (key == "2-7") {

        getQueryCustomerCalendar({
          pageSize: 999,
          pageNum: 1,

        }).then((res) => {
          if (res.code == 200) {
            this.dialogVisibleYear = true
            this.listCount = [];
            this.dayList = res.data;
            res.data.forEach((item) => {
              this.listCount.push(item.actList);
            });
            this.listCount = this.listCount.flat()
            this.listCount=this.listCount.sort((a, b) => new Date(a.day) - new Date(b.day));
            this.dayList.forEach((item) => {
              item.daytext = "";
              if (item.actList.length != 0) {
                item.actList.forEach((itemSon) => {

                  item.daytext = itemSon.text + "," + item.daytext;
                });
              }

            });
            let date = new Date();
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, "0");
            let day = date.getDate().toString().padStart(2, "0");
            let formattedDate = `${year}-${month}-${day}`;
            let index = this.dayList.findIndex((item) => item.day === formattedDate);
            if (index != -1) {
              this.listDayList = this.dayList[index].actList;
            } else {
              this.listDayList = [];
            }


          }
        })
      }
      if (key == "2-5") {
        getQueryCustomerAnnualTask({ pageSize: 10, pageNum: 1 }).then((res) => {
          if (res.code == 200) {
            this.dialogFormVisibleYear = true;
            this.monyth = [res.data];
          }
        });
      }
      if (key == "2-3-1") {
        getSaveCustomerMsg({
          brandName: this.$route.query.brandName,
          organizationName: "广州",
        }).then((res) => {
          this.organizationNames = "广州";
          this.$store.dispatch(
            "user/setPriceChangeList",
            JSON.stringify(res.data.customerpriceadjustments)
          );
          localStorage.setItem(
            "priceChangeList",
            JSON.stringify(res.data.customerpriceadjustments)
          );
          localStorage.setItem("organizationNames", "广州");
          if (res.code == 200) {
            window.open(
              this.$locationViewUrl + "#/goods/GoodsHome?brandName=" + this.brandName,
              "_blank"
            );
            this.dialogFormVisible = false;
          }
        });
      }
      if (key == "2-3-2") {
        getSaveCustomerMsg({
          brandName: this.$route.query.brandName,
          organizationName: "迪拜",
        }).then((res) => {
          if (res.code == 200) {
            this.organizationNames = "迪拜";
            this.$store.dispatch(
              "user/setPriceChangeList",
              JSON.stringify(res.data.customerpriceadjustments)
            );
            localStorage.setItem(
              "priceChangeList",
              JSON.stringify(res.data.customerpriceadjustments)
            );
            localStorage.setItem("organizationNames", "迪拜");
            window.open(
              this.$locationViewUrl + "#/goods/GoodsHome?brandName=" + this.brandName,
              "_blank"
            );
            this.dialogFormVisible = false;
          }
        });
      }
      if (key == "2-4-1") {
        this.$store.dispatch("user/setLange", "中文");
        localStorage.setItem("lange", "中文");
        console.log(this.lange);
        if (this.organizationNameNew == "Guangzhou") {
          this.organizationNameNew = "广州";
        }
        if (this.organizationNameNew == "Dubai") {
          this.organizationNameNew = "迪拜";
        }
        if (this.organizationNameNew == "广州") {
          this.organizationNameNew = "广州";
        }
        if (this.organizationNameNew == "迪拜") {
          this.organizationNameNew = "迪拜";
        }
      }
      if (key == "2-4-2") {
        this.$store.dispatch("user/setLange", "English");
        localStorage.setItem("lange", "English");
        console.log(this.lange);
        if (this.organizationNameNew == "Guangzhou") {
          this.organizationNameNew = "Guangzhou";
        }
        if (this.organizationNameNew == "Dubai") {
          this.organizationNameNew = "Dubai";
        }
        if (this.organizationNameNew == "广州") {
          this.organizationNameNew = "Guangzhou";
        }
        if (this.organizationNameNew == "迪拜") {
          this.organizationNameNew = "Dubai";
        }
      }
    },
    handleDel(row) {
      console.log(row);
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dcMarketingcenterMarketingcalendarDel(row.marketingcalendarid).then((res) => {
          if (res.code == 200) {
            this.init();
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        });

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    init() {
      this.dayloading = true;
      getQueryCustomerCalendar().then((res) => {
        this.dayloading = false;
        if (res.code == 200) {
          this.listCount = [];
          this.dayList = res.data;
          res.data.forEach((item) => {
            this.listCount.push(item.actList);
          });
          this.listCount = this.listCount.flat()
          this.listCount=this.listCount.sort((a, b) => new Date(a.day) - new Date(b.day));
          this.dayList.forEach((item) => {
            item.daytext = "";
            if (item.actList.length != 0) {
              item.actList.forEach((itemSon) => {

                item.daytext = itemSon.text + "," + item.daytext;
              });
            }

          });
          this.dayList.forEach((item) => {
            item.daytext = "";
            if (item.actList.length != 0) {
              item.actList.forEach((itemSon) => {

                item.daytext = itemSon.text + "," + item.daytext;
              });
            }

          });
          let date = new Date();
          let year = date.getFullYear();
          let month = (date.getMonth() + 1).toString().padStart(2, "0");
          let day = date.getDate().toString().padStart(2, "0");
          let formattedDate = `${year}-${month}-${day}`;
          let index = this.dayList.findIndex((item) => item.day === formattedDate);
          if (index != -1) {
            this.listDayList = this.dayList[index].actList;
          } else {
            this.listDayList = [];
          }
        }
      });
    },
    handleSetUp(title, row) {
      this.$refs.addDay.show(title, row);
    },
    handleDate(date) {
      console.log(date);
      let index = this.dayList.findIndex((item) => item.day === date.day);
      if (index != -1) {
        this.list = this.dayList[index].actList;
      } else {
        this.list = [];
      }
    },
    queryDataClear() { },
    allHandleCopy(row) { },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    queryData() {
      this.queryForm.pageNum = 1;
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
    },

    goToHotGood() {
      window.open(
        this.$locationViewUrl + "#/goods/hotGoodViewCounter?brandName=" + this.brandName,
        "_blank"
      );
    },
    goToNewGood() {
      window.open(
        this.$locationViewUrl + "#/goods/newGoodViewCounter?brandName=" + this.brandName,
        "_blank"
      );
    },
    goToTUIGood() {
      window.open(
        this.$locationViewUrl + "#/goods/tuiGoodViewCounter?brandName=" + this.brandName,
        "_blank"
      );
    },
    goInTransitPreSale() {
      window.open(
        this.$locationViewUrl + "#/goods/inTransitPreSale?brandName=" + this.brandName,
        "_blank"
      );
    },
    goInPproductionPreSale() {
      window.open(
        this.$locationViewUrl +
        "#/goods/inPproductionPreSale?brandName=" +
        this.brandName,
        "_blank"
      );
    },
    goAboutUs() {
      window.open(
        this.$locationViewUrl + "#/goods/Introduce?brandName=" + this.brandName,
        "_blank"
      );
    },
    goNews() {
      window.open(
        this.$locationViewUrl + "#/goods/news?brandName=" + this.brandName,
        "_blank"
      );
    },
    chageBrand(item) {
      if (this.organizationName == "迪拜") {
        this.organizationNameNewName = "迪拜";
      } else {
        this.organizationNameNewName = "广州";
      }
      if (item == "CHRISBELLA") {
        getSaveCustomerMsg({
          brandName: "Chrisbella",
          organizationName: this.organizationNameNewName,
        }).then((res) => {
          if (res.code == 200) {
            this.$store.dispatch(
              "user/setPriceChangeList",
              JSON.stringify(res.data.customerpriceadjustments)
            );
            localStorage.setItem("brandName", "CHRISBELLA");
            localStorage.setItem(
              "priceChangeList",
              JSON.stringify(res.data.customerpriceadjustments)
            );
            window.open(
              this.$locationViewUrl + "#/goods/GoodsHome?brandName=CHRISBELLA",
              "_blank"
            );
            this.dialogFormVisible = false;
          }
        });
      }
      if (item == "BAGCO") {
        getSaveCustomerMsg({
          brandName: "BAGCO",
          organizationName: this.organizationNameNewName,
        }).then((res) => {
          if (res.code == 200) {
            this.$store.dispatch(
              "user/setPriceChangeList",
              JSON.stringify(res.data.customerpriceadjustments)
            );
            localStorage.setItem("brandName", "BAGCO");
            localStorage.setItem(
              "priceChangeList",
              JSON.stringify(res.data.customerpriceadjustments)
            );
            window.open(
              this.$locationViewUrl + "#/goods/GoodsHome?brandName=BAGCO",
              "_blank"
            );
            this.dialogFormVisible = false;
          }
        });
      }
      if (item == "SUSEN零售") {
        getSaveCustomerMsg({
          brandName: "SUSEN零售",
          organizationName: this.organizationNameNewName,
        }).then((res) => {
          if (res.code == 200) {
            this.$store.dispatch(
              "user/setPriceChangeList",
              JSON.stringify(res.data.customerpriceadjustments)
            );
            localStorage.setItem("brandName", "SUSEN零售");
            localStorage.setItem(
              "priceChangeList",
              JSON.stringify(res.data.customerpriceadjustments)
            );
            window.open(
              this.$locationViewUrl + "#/goods/GoodsHome?brandName=SUSEN零售",
              "_blank"
            );
            this.dialogFormVisible = false;
          }
        });
      }
    },
    chageBrand1(item) {
      if (item == "b") {
        getSaveCustomerMsg({
          brandName: this.$route.query.brandName,
          organizationName: "广州",
        }).then((res) => {
          this.organizationNames = "广州";
          this.$store.dispatch(
            "user/setPriceChangeList",
            JSON.stringify(res.data.customerpriceadjustments)
          );
          localStorage.setItem(
            "priceChangeList",
            JSON.stringify(res.data.customerpriceadjustments)
          );
          localStorage.setItem("organizationNames", "广州");
          if (res.code == 200) {
            window.open(
              this.$locationViewUrl + "#/goods/GoodsHome?brandName=" + this.brandName,
              "_blank"
            );
            this.dialogFormVisible = false;
          }
        });
      }
      if (item == "d") {
        getSaveCustomerMsg({
          brandName: this.$route.query.brandName,
          organizationName: "迪拜",
        }).then((res) => {
          if (res.code == 200) {
            this.organizationNames = "迪拜";
            this.$store.dispatch(
              "user/setPriceChangeList",
              JSON.stringify(res.data.customerpriceadjustments)
            );
            localStorage.setItem(
              "priceChangeList",
              JSON.stringify(res.data.customerpriceadjustments)
            );
            localStorage.setItem("organizationNames", "迪拜");
            window.open(
              this.$locationViewUrl + "#/goods/GoodsHome?brandName=" + this.brandName,
              "_blank"
            );
            this.dialogFormVisible = false;
          }
        });
      }
    },
    chageBrand2(item) {
      if (item == "b") {
        this.$store.dispatch("user/setLange", "中文");
        localStorage.setItem("lange", "中文");
        console.log(this.lange);
        if (this.organizationNameNew == "Guangzhou") {
          this.organizationNameNew = "广州";
        }
        if (this.organizationNameNew == "Dubai") {
          this.organizationNameNew = "迪拜";
        }
        if (this.organizationNameNew == "广州") {
          this.organizationNameNew = "广州";
        }
        if (this.organizationNameNew == "迪拜") {
          this.organizationNameNew = "迪拜";
        }
      }
      if (item == "d") {
        this.$store.dispatch("user/setLange", "English");
        localStorage.setItem("lange", "English");
        console.log(this.lange);
        if (this.organizationNameNew == "Guangzhou") {
          this.organizationNameNew = "Guangzhou";
        }
        if (this.organizationNameNew == "Dubai") {
          this.organizationNameNew = "Dubai";
        }
        if (this.organizationNameNew == "广州") {
          this.organizationNameNew = "Guangzhou";
        }
        if (this.organizationNameNew == "迪拜") {
          this.organizationNameNew = "Dubai";
        }
      }
    },
    handleMouseOver1(item) {
      if (item == "Chrisbella") {
        this.$refs.Chrisbella.style.background = "#edf2fa";
      }
      if (item == "BAGCO") {
        this.$refs.BAGCO.style.background = "#edf2fa";
      }
      if (item == "SUSEN零售") {
        this.$refs.SUSEN.style.background = "#edf2fa";
      }
    },
    handleMouseLeave1(item) {
      if (item == "Chrisbella") {
        this.$refs.Chrisbella.style.background = "#c7c7c7";
      }
      if (item == "BAGCO") {
        this.$refs.BAGCO.style.background = "#c7c7c7";
      }
      if (item == "SUSEN零售") {
        this.$refs.SUSEN.style.background = "#c7c7c7";
      }
    },
    dropdownItemClick(event) {
      console.log(event);
      if (event == "d") {
        localStorage.clear();
        sessionStorage.clear();
        window.open(
          this.$locationViewUrl + "#/MallHomepage?brandName=" + this.brandName,
          "_blank"
        );
      } else {
        this.$router.push("/goods/ChangePwd");
      }
    },
    dropdownItemClicks(event) {
      // console.log(event);
      // if (event == "d") {
      //   localStorage.clear();
      //   sessionStorage.clear();
      //   window.open(this.$locationViewUrl + "#/MallHomepage", "_blank");
      // }
    },
    handleScroll() {
      // 获取滚动条的位置
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (this.scrollHeight - scrollTop > 0) {
        this.navShow = true;
      } else {
        this.navShow = false;
      }
      this.scrollHeight = scrollTop;
      console.log(this.navShow);
      // 如果滚动到页面底部附近（这里设置为距离底部100px），可以执行你的操作
      // if (scrollHeight - scrollTop - clientHeight < 100) {
      //   // 执行你的操作，例如加载更多内容等
      //  console.log(1)
      // }
    },
    getAdvertList() {
      getCaptchaImage().then((res) => {
        if (res.code == 200) {
          this.images = "data:image/gif;base64," + res.data.img;
          console.log(this.images);
          this.form.uuid = res.data.uuid;
          localStorage.setItem("uuid", res.data.uuid);
        }
      });
    },

    changeColor() {
      this.$nextTick(() => {
        this.goodTypeShow = true;
      });
    },
    resetBorderColor() {
      this.$nextTick(() => {
        this.goodTypeShow = false;
      });
    },

    gotoLogin() {
      this.getAdvertList();
      this.dialogFormVisible = true;
    },
    changselect(index, indexPath) {
      if (index == 1) {
        const targetDiv = document.querySelector(".new_goods_title_hot");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 2) {
        const targetDiv = document.querySelector(".new_goods_title_new");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 3) {
        const targetDiv = document.querySelector(".new_goods_title_new1");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 4) {
        const targetDiv = document.querySelector(".new_goods_title_new2");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 6) {
        const targetDiv = document.querySelector(".new_goods_title_Image_store");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else {
        const targetDiv = document.querySelector(".section4");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.$router.push("/m_index");
      }
    },
    backgroundImg(value) {
      var host = document.location.host;
      var result = "";
      if (value && value.indexOf("files") != -1) {
        if (host.search("192") != -1) {
          result = "http://192.168.0.50:8080" + value;
        } else {
          result = document.location.protocol + "//" + host + "/" + value;
        }
      } else {
        result = value;
      }
      return result;
    },
    //公告列表
    getArticle() {
      this.$request.post({
        url: "/ReportApi/business/newlists",
        params: {
          pageNo: 1,
          pageSize: 3,
          type: 1,
        },
        success: (res) => {
          this.articleList = res[0];
        },
      });
    },
    //新闻列表
    getArticle2() {
      this.$request.post({
        url: "/ReportApi/business/newlists",
        params: {
          pageNo: 1,
          pageSize: 6,
          type: 2,
        },
        success: (res) => {
          this.articleList2 = res[0];
        },
      });
    },
    //热销商品列表
    getHotGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goodlist",
        params: {
          pageNo: 1,
          pageSize: 11,
          sort: 3,
          tenantid: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.hotgoodsList = res[0];
          if (this.hotgoodsList.length < 11) {
            this.hotNull = 11 - this.hotgoodsList.length;
          }
          this.getNewestGoodsList();
        },
      });
    },
    //最新商品
    getNewestGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goodlist",
        params: {
          pageNo: 1,
          pageSize: 11,
          sort: 5,
          tenantid: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.newestGoodsList = res[0];
          if (this.newestGoodsList.length < 11) {
            this.newNull = 11 - this.newestGoodsList.length;
          }
        },
      });
    },
    buyGoods() {
      if (this.organizationNames == "迪拜") {
        window.open(
          this.$locationViewUrl + "#/goods/goodOrderDB?brandName=" + this.brandName,
          "_blank"
        );
      } else {
        window.open(
          this.$locationViewUrl + "#/goods/goodOrder?brandName=" + this.brandName,
          "_blank"
        );
      }
    },
    buyGoodCoCreationCenter() {
      if (this.organizationNames == "迪拜") {
        window.open(
          this.$locationViewUrl + "#/goods/coCreationCenter?brandName=" + this.brandName,
          "_blank"
        );
      } else {
        window.open(
          this.$locationViewUrl + "#/goods/coCreationCenter?brandName=" + this.brandName,
          "_blank"
        );
      }
    },

    buyGood(item) {
      if (this.organizationNames == "迪拜") {
        window.open(
          this.$locationViewUrl + "#/goods/goodsCartDB?brandName=" + this.brandName,
          "_blank"
        );
      } else {
        window.open(
          this.$locationViewUrl + "#/goods/goodsCart?brandName=" + this.brandName,
          "_blank"
        );
      }

      // this.$store.commit('getGoodsInfo', item)
    },
    GoHome() {
      window.open(
        this.$locationViewUrl + "#/goods/GoodsHome?brandName=" + this.brandName,
        "_blank"
      );
    },
    GoGood() {
      window.open(
        this.$locationViewUrl + "#/goods/goodsList?brandName=" + this.brandName,
        "_blank"
      );
    },
    buyGoodRepeatOrder() {
      window.open(
        this.$locationViewUrl + "#/goods/repeatOrder?brandName=" + this.brandName,
        "_blank"
      );
    },
    openQQ() {
      var url =
        "tencent://message/?uin=" +
        this.$store.state.tenantInfo.qq +
        "&amp;Site=&amp;Menu=yes";
      window.open(url, "_self");
    },

    toUrl(url) {
      if (url.indexOf("http") != -1) {
        window.open(url);
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>
<style>
/* .el-drawer__body {
  background: #c7c7c7;
} */
</style>
<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 154px;
  min-height: 400px;
}

/deep/ .el-calendar-day:hover {
  background-color: #3499dd;
  color: #000;
  cursor: pointer;
}

/deep/ .flex .el-calendar-table td.is-selected {
  background-color: skyblue;
}

/deep/.flex .el-calendar-table td.el-calendar-day:hover {
  background-color: #3499dd !important;
}

html {
  font-size: 18px;

  /* 默认字体大小 */
  @media only screen and (max-width: 1200px) {
    font-size: 14px;
    /* 中等屏幕 */
  }

  @media only screen and (max-width: 768px) {
    font-size: 9px;
    /* 中等屏幕 */
  }

  @media only screen and (max-width: 480px) {
    font-size: 6px;
    /* 中等屏幕 */
  }
}

#indexThree {
  .active {
    color: #66b1ff;
    background: #ecf5ff;
  }

  .counter1 {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 70%;
    padding: 5px;
    border: 1px solid #8e8e8e;
    position: relative;
    line-height: 31px;
    border-radius: 40px;
  }

  .header_wrap {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;

    .main {

      // padding-bottom: 200px;
      .header {
        .top_header {
          background: #f5f5f5;
          border-bottom: #eaeaea;

          a {
            color: #999;

            &:hover {
              color: #333;
            }
          }

          .wrap {
            width: 1400px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 39px;
            color: #999;
            font-size: 12px;
          }

          .top_right {
            li {
              padding: 0 15px;
              border-right: 1px solid #e1e1e1;
              display: inline-block;
              cursor: pointer;
            }
          }
        }

        .center_header {
          height: 125px;
          width: 1400px;

          // width: 1265px;
          .logo {
            width: 220px;
            height: 105px;
            margin-top: 10px;
          }

          .nav {
            margin-left: 50px;

            ul {
              float: left;
              // width: 688px;
              width: 610px;
              display: block;
              margin-top: 50px;
              margin-right: 30px;
            }

            li {
              float: left;
              margin: 0 4px;
              padding: 0 4px;
              font-size: 16px;
              color: #999;

              &:hover {
                cursor: pointer;
                color: #f80;
              }
            }

            .router-link-exact-active {
              color: #f80;
            }
          }

          .head_menu_user {
            margin-top: 50px;

            img {
              margin-right: 10px;
            }

            .info {
              color: #999;
              line-height: 25px;
              font-size: 14px;

              .red {
                color: #f80;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-flex;
                max-width: 90px;
              }

              .logout {
                margin-left: 15px;
                cursor: pointer;
              }
            }
          }
        }
      }

      .login_header {
        padding-top: 30px;

        .login_img {
          display: flex;
          align-items: center;

          div:hover {
            cursor: pointer;
          }

          p {
            color: #8c8c8c;
            margin-left: 15px;
          }
        }

        .login_text {
          line-height: 130px;
          font-size: 14px;

          a {
            color: #f80;
          }
        }
      }
    }
  }

  .footer_wrap {
    height: 200px;

    padding-top: 30px;

    ul {
      li {
        display: inline-block;
        width: 270px;
        height: 56.5px;

        img {
          width: 74px;
          margin-top: -7px;
        }

        .info {
          margin-left: 20px;
          vertical-align: top;

          .title {
            color: #333;
            font-size: 16px;
            font-weight: bold;
            line-height: 36px;
          }

          .desc {
            font-size: 12px;
            color: #999;
          }
        }

        &:not(:first-child) {
          margin-left: 33px;
        }
      }
    }

    .copyright {
      text-align: center;
      font-size: 12px;
      color: #666;
      margin-top: 48px;
      clear: both;

      .jc {
        line-height: 50px;
      }

      .link {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        p {
          display: flex;
          flex-wrap: wrap;
        }

        a {
          margin-right: 6px;
          white-space: nowrap;
        }
      }
    }
  }

  .banner {
    position: relative;
    cursor: pointer;
  }

  .banner_sub_width {
    height: 400px;

    .banner_sub {
      width: 320px;
      margin-top: 10px;
      height: 400px;
      background: #fff;
      border-radius: 4px;
    }

    .login_avatar {
      color: #999;
      text-align: center;
      font-size: 19px;

      img {
        border-radius: 50%;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 80px;
        height: 80px;
      }
    }

    .to_user_center {
      width: 220px;
      padding: 48px 0;

      span {
        background: #f80;
        color: #fff;
        line-height: 30px;
        width: 360px;
        text-align: center;
        border-radius: 4px;
        margin: 0 auto;
        display: block;
        cursor: pointer;
      }
    }

    .login_btn {
      margin-top: 10px;

      .left_login {
        width: 83px;
        line-height: 31px;
        font-size: 14px;
        border: 1px solid #f80;
        border-radius: 3px;
        margin-left: 28px;
        text-align: center;

        &:hover {
          cursor: pointer;
        }

        &:first-child {
          color: #f80;

          &:hover {
            background: #f80;
            color: #fff;
          }
        }

        &:last-child {
          background: #f80;
          color: #fff;
        }
      }
    }

    .sub_gg {
      padding: 8px 25px;
      box-sizing: border-box;
      margin-top: 10px;
      border-top: 1px solid #f1f1f1;
      font-size: 12px;

      h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }

      li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 25px;
        color: #999;
      }
    }

    .other_block {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .block {
        border-top: 1px solid #dedede;
        float: left;
        width: 140px;
        height: 60px;
        background: #fbfbfb;
        box-sizing: border-box;
        font-size: 14px;
        text-align: center;

        &:first-child,
        &:nth-child(3) {
          border-right: 1px solid #dedede;
        }

        img {
          width: 30px;
          height: 30px;
          margin-top: 8px;
          margin-bottom: 5px;
        }

        &:hover {
          background: #eaeaea;
          cursor: pointer;
        }
      }
    }
  }

  .main {
    .advertising {
      display: flex;
      margin-top: 10px;

      div {
        flex: 1;

        img {
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    .recommend {
      margin-top: 20px;

      .item {
        height: 470px;
        margin-left: 20px;
        margin-bottom: 12px;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 360px;
            height: 280px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:nth-child(1),
        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        dl {
          width: 360px;
          border: 1px solid #eee;

          &:hover {
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          }

          img {
            width: 360px;
            height: 360px;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            color: #999;
            line-height: 28px;
          }

          .other {
            .price {
              font-size: 18px;
              color: #f80;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            width: 150px;
            line-height: 28px;
            text-align: center;
            color: #f80;
            border: 1px solid #f80;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 6px auto 10px auto;
            display: block;
            height: 28px;
            font-size: 14px;

            &:hover {
              color: #fff;
              background: #f80;
            }
          }
        }
      }

      .items {
        height: 470px;
        margin-left: 10px;
        margin-bottom: 12px;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 360px;
            height: 280px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:nth-child(1),
        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        dl {
          width: 360px;
          border: 1px solid #eee;

          &:hover {
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          }

          img {
            width: 360px;
            height: 360px;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            color: #999;
            line-height: 28px;
          }

          .other {
            .price {
              font-size: 18px;
              color: #f80;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            width: 243px;
            line-height: 28px;
            text-align: center;
            color: #f80;
            border: 1px solid #f80;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 6px auto 10px auto;
            display: block;
            height: 28px;
            font-size: 14px;

            &:hover {
              color: #fff;
              background: #f80;
            }
          }
        }
      }

      .null {
        width: 188px;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .new_goods_title_hot {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .new_goods_title_new {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .new_goods_title_new1 {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .new_goods_title_new2 {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .new_goods_title_Image_store {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .section4 {
      position: relative;
      text-align: center;
      margin-bottom: 25px;
      background: #fff;

      &>span {
        position: relative;
        display: inline-block;
        margin: 25px auto;
        font-weight: 700;
        font-size: 22px;

        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          display: inline-block;
          background: -webkit-gradient(linear,
              left top,
              right top,
              from(rgba(255, 166, 0, 0.929412)),
              to(#ff6a00));
          background: linear-gradient(90deg, rgba(255, 166, 0, 0.929412), #ff6a00);
          width: 40%;
          border-radius: 50px;
          height: 4px;
        }
      }

      .article_list {
        text-align: left;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;

        .article_item {
          border: 1px solid #eee;
          padding: 10px 30px;
          margin: 10px 0;
          width: 44%;
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 5px 1px #dcdfe6;

            .title a {
              color: #ff6a00;
            }
          }

          .title {
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-bottom: 10px;
          }

          .summary {
            color: #767676;
            font-size: 14px;
          }

          .title a {
            font-size: 18px;
            color: #333;
          }

          .title {
            p {
              color: #999;
            }
          }
        }
      }
    }
  }

  .xian {
    height: 5px;
    background: #eee;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    height: 60px;
  }

  li {
    display: inline-block;
    padding: 0;
    border-bottom: 2px solid transparent;
    /* 初始下边框颜色 */
    transition: border-bottom-color 0.3s;
    /* 添加过渡效果 */
    color: white;
  }

  li:hover {
    // text-decoration: underline;
    border-bottom: 2px solid white;
  }

}
</style>
