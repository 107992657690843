<template>
    <el-dialog :visible.sync="dialogFormVisible" append-to-body width="30%" :close-on-click-modal="false"
      :title="title">
      <div >
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="日期">
            <el-date-picker v-model="form.day" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item label="节日">
            <el-input v-model="form.text" autocomplete="off" style="width:52.5%"></el-input>
          </el-form-item>
          <el-form-item label="节日备注">
            <el-input v-model="form.remrak" autocomplete="off" style="width:52.5%" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center;">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
        </div>
      </div>
    
    </el-dialog>
  </template>
  
  <script>
  import { addCustomerCalendar,upadteCustomerCalendar } from "@/api/good/index.js";

  
  export default {
    
    data() {
      return {
        form: {},
        dialogFormVisible: false,
        gjOption: [],
        options: [],
        loading: false,
        title: ""
      }
    },
    created() {
     
    },
    methods: {
      hanldeCC(row) {
        console.log(row)
        this.$set(this.form, 'cuscode', row.ccusCode)
        this.$set(this.form, 'ccusName', row.ccusName)
        this.$set(this.form, 'beforecreditlimit', row.creditlimit)
      },
      handleClient() {
        this.$refs.clients.show()
      },
      handleCustomer() {
        this.$refs.clients.show()
      },
      submit() {
        this.loading = true
        if (this.title == '新增营销日历') {
            addCustomerCalendar(this.form).then(res => {
            this.loading = false
            if (res.code == 200) {
              this.dialogFormVisible = false
              this.$emit('init')
            }
          })
        } else {
            upadteCustomerCalendar(this.form).then(res => {
            this.loading = false
            if (res.code == 200) {
              this.dialogFormVisible = false
              this.$emit('init')
            }
          })
        }
      },
      show(title, row) {
        this.title = title + '营销日历'
        this.form = { ...row }
        this.dialogFormVisible = true
      },
      getdcBasearchiveRegion() {
        let params = {
          pageSize: 999,
          pageNum: 1
        }
        dcBasearchiveRegion(params).then(res => {
          if (res.code == 200) {
            this.gjOption = res.data.list
          }
        })
      }
    },
  }
  </script>