<template>
  <div id="MallHomepage">
    <div class="container">
      <div
        class="column"
        @click="buyGoods(1)"
        style="
          background-image: url('https://www.aopiya.com/file/abyFile/backGround/CB.jpg');
          background-size: 100% 100%;
          background-position: center;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/logo-19.png"
          alt=""
          style="margin: 0 auto"
        />
      </div>
      <div
        class="column"
        @click="buyGoods(2)"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url('https://www.aopiya.com/file/abyFile/backGround/susen.jpg');
          background-size: 100% 100%;
          background-position: center;
          background-repeat: no-repeat;
        "
      >
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/logo-21.png"
          alt=""
          style="margin: 0 auto"
        />
      </div>
      <div
        class="column"
        @click="buyGoods(3)"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url('https://www.aopiya.com/file/abyFile/backGround/BC.jpg');
          background-size: 100% 100%;
          background-position: center;
          background-repeat: no-repeat;
        "
      >
        <img
          src="https://www.aopiya.com/file/abyFile/backGround/logo-20.png"
          alt=""
          style="margin: 0 auto"
        />
      </div>
    </div>
    <el-drawer
      title=""
      :visible.sync="dialogFormVisible"
      size="30%"
      direction="rtl"
      v-if="lange == '中文'"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 83vh;
          margin-top: 6vh;
        "
      >
        <div>
          <div
            style="
              width: 80%;
              margin-bottom: 20px;
              color: #72767b;
              font-size: 16px;
              margin-left: 10%;
              display: flex;
            "
          >
            <div style="margin-right: 40%; color: #ff8a00">会员登录</div>
            <div @click="registerHuman">用户注册</div>
          </div>

          <div
            style="display: flex; margin-left: 10%; margin-bottom: 20px; font-size: 14px"
          >
            <div>语言：</div>
            <div style="margin-left: 10%" @click="langeChange('中文')">中文</div>
            <div style="margin-left: 10%" @click="langeChange('English')">English</div>
          </div>
          <el-form ref="form" :model="form" style="width: 80%; margin-left: 10%">
            <el-form-item label="账户名称：">
              <div style="display: flex">
                <el-select v-model="form.regionId" filterable placeholder="请选择">
                  <el-option
                    v-for="item in optionRegion"
                    :key="item.regionId"
                    :label="item.label"
                    :value="item.regionId"
                  >
                  </el-option>
                </el-select>
                <el-input v-model="form.usernameCopy" placeholder="账户名"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="账号密码：">
              <div style="display: flex">
                <el-input
                  v-model="form.password"
                  placeholder="密码"
                  show-password
                ></el-input>
              </div>
            </el-form-item>
            <!-- <el-form-item label="营销组织：">
              <div style="display: flex">
                <el-select
                  v-model="form.organizationName"
                  placeholder="营销组织"
                  @change="selectChange"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item> -->
            <!-- <el-form-item label="语言：">
              <div style="display: flex">
                <el-select
                  v-model="lange"
                  filterable
                  placeholder="请选择"
                  @change="langeChange"
                >
                  <el-option
                    v-for="item in optionsLange"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item> -->
            <el-form-item label="验证数码：">
              <div style="display: flex">
                <el-input v-model="form.code" placeholder="验证码"></el-input>
                <div style="width: 40%">
                  <img
                    :src="images"
                    @click="getAdvertList"
                    style="width: 100%; height: 40px"
                  />
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>

        <!--  -->
        <div style="">
          <!-- <div
            style="
              width: 100%;
              justify-content: center;
              margin-top: 20px;
              display: flex;
              height: 50px;
            "
          >
            <div
              class="counter1"
              style="
                font-weight: 500;
                background: black;
                color: white;
                text-align: center;
                justify-content: center;
              "
              @click="registerHuman"
            >
              <div>注册</div>
            </div>
          </div> -->

          <div
            style="
              width: 100%;
              justify-content: center;
              margin-top: 20px;
              display: flex;
              height: 50px;
            "
          >
            <div
              class="counter1"
              style="
                font-weight: 500;
                background: black;
                color: white;
                text-align: center;
                justify-content: center;
              "
              @click="handleLogin"
            >
              <div>登陆</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-drawer
      title=""
      :visible.sync="dialogFormVisible"
      size="30%"
      direction="rtl"
      v-else
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 83vh;
          margin-top: 6vh;
        "
      >
        <div>
          <div
            style="
              width: 80%;
              margin-bottom: 20px;
              color: #72767b;
              font-size: 16px;
              margin-left: 10%;
              display: flex;
            "
          >
            <div style="margin-right: 40%; color: #ff8a00">Member Log In</div>
            <div @click="registerHuman">User Registration</div>
          </div>
          <div
            style="display: flex; margin-left: 10%; margin-bottom: 20px; font-size: 14px"
          >
            <div>Lange：</div>
            <div style="margin-left: 10%" @click="langeChange('中文')">中文</div>
            <div style="margin-left: 10%" @click="langeChange('English')">English</div>
          </div>
          <el-form ref="form" :model="form" style="width: 80%; margin-left: 10%">
            <el-form-item label="Account Number:">
              <div style="display: flex">
                <el-select v-model="form.regionId" filterable placeholder=" ">
                  <el-option
                    v-for="item in optionRegion"
                    :key="item.regionId"
                    :label="item.label"
                    :value="item.regionId"
                  >
                  </el-option>
                </el-select>
                <el-input v-model="form.usernameCopy" placeholder=""></el-input>
              </div>
            </el-form-item>
            <el-form-item label="Password：">
              <div style="display: flex">
                <el-input v-model="form.password" placeholder="" show-password></el-input>
              </div>
            </el-form-item>
            <!-- <el-form-item label="Sales Organization：">
              <div style="display: flex">
                <el-select
                  v-model="form.organizationName"
                  placeholder=""
                  @change="selectChange"
                >
                  <el-option
                    v-for="item in optionsEnglish"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item> -->
            <!-- <el-form-item label="Lange：">
            <div style="display: flex">
              <el-select v-model="lange" filterable placeholder="" @change="langeChange">
                <el-option
                  v-for="item in optionsLange"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item> -->
            <el-form-item label="Verification Code：">
              <div style="display: flex">
                <el-input v-model="form.code" placeholder=""></el-input>
                <div style="width: 40%">
                  <img
                    :src="images"
                    @click="getAdvertList"
                    style="width: 100%; height: 40px"
                  />
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <!--  -->
        <div style="">
          <!-- <div
            style="
              width: 100%;
              justify-content: center;
              margin-top: 20px;
              display: flex;
              height: 50px;
            "
          >
            <div
              class="counter1"
              style="
                font-weight: 500;
                background: black;
                color: white;
                text-align: center;
                justify-content: center;
              "
              @click="registerHuman"
            >
              <div v-if="lange == '中文'">注册</div>

              <div v-else>Register</div>
            </div>
          </div> -->

          <div
            style="
              width: 100%;
              justify-content: center;
              margin-top: 20px;
              display: flex;
              height: 50px;
            "
          >
            <div
              class="counter1"
              style="
                font-weight: 500;
                background: black;
                color: white;
                text-align: center;
                justify-content: center;
              "
              @click="handleLogin"
            >
              <div v-if="lange == '中文'">登陆</div>
              <div v-else>Login</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-drawer
      title=""
      :visible.sync="dialogFormVisibles"
      size="30%"
      v-if="lange == '中文'"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 83vh;
          margin-top: 6vh;
        "
      >
        <el-form
          :model="formRegister"
          label-position="left"
          style="width: 80%; margin-left: 10%"
          :rules="rules"
          ref="ruleForm"
        >
          <div
            style="
              margin-bottom: 20px;
              color: #72767b;
              font-size: 16px;

              display: flex;
            "
          >
            <div style="margin-right: 40%" @click="dialogFormVisibles = false">
              会员登录
            </div>
            <div style="color: #ff8a00">用户注册</div>
          </div>
          <div style="display: flex; margin-bottom: 20px; font-size: 14px">
            <div>语言：</div>
            <div style="margin-left: 10%" @click="langeChange('中文')">中文</div>
            <div style="margin-left: 10%" @click="langeChange('English')">English</div>
          </div>
          <el-form-item prop="phoneCopy">
            <div style="display: flex">
              <div style="width: 280px">手机号码：</div>
              <el-select v-model="formRegister.regionId" filterable placeholder="请选择">
                <el-option
                  v-for="item in optionRegion"
                  :key="item.regionId"
                  :label="item.label"
                  :value="item.regionId"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="formRegister.phoneCopy"
                placeholder="手机号码"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <div style="display: flex">
              <div style="width: 180px">密码：</div>
              <el-input
                v-model="formRegister.password"
                show-password
                placeholder="密码"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="passwordOther">
            <div style="display: flex">
              <div style="width: 180px">确认密码：</div>
              <el-input
                v-model="formRegister.passwordOther"
                show-password
                placeholder="确认密码"
              ></el-input>
            </div>
          </el-form-item>
          <!-- <el-form-item>
            <div style="display: flex">
              <div style="width: 130px">语言</div>
              <el-select v-model="lange" filterable placeholder="" @change="langeChange">
                <el-option
                  v-for="item in optionsLange"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item> -->
          <el-form-item prop="customername">
            <div style="display: flex">
              <div style="width: 180px">公司名称：</div>
              <el-input
                v-model="formRegister.customername"
                placeholder="公司名称"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="linkman">
            <div style="display: flex">
              <div style="width: 180px">客户联系人：</div>
              <el-input v-model="formRegister.linkman" placeholder="联系人"></el-input>
            </div>
          </el-form-item>

          <!-- <el-form-item>
            <el-input v-model="form.username" placeholder="联系人"></el-input>
          </el-form-item> -->
        </el-form>
        <!--  -->
        <div
          style="
            width: 100%;
            justify-content: center;
            margin-top: 20px;
            display: flex;
            height: 50px;
          "
        >
          <div
            class="counter1"
            style="
              font-weight: 500;
              background: black;
              color: white;
              text-align: center;
              justify-content: center;
            "
            @click="formRegisterClick"
          >
            <div>注册</div>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-drawer title="" :visible.sync="dialogFormVisibles" size="30%" v-else>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 83vh;
          margin-top: 6vh;
        "
      >
        <el-form
          :model="formRegister"
          label-position="left"
          style="width: 80%; margin-left: 10%"
          :rules="rules"
          ref="ruleForm"
        >
          <div
            style="
              margin-bottom: 20px;
              color: #72767b;
              font-size: 16px;

              display: flex;
            "
          >
            <div style="margin-right: 40%" @click="dialogFormVisibles = false">
              Member Log In
            </div>
            <div style="color: #ff8a00">Register an account</div>
          </div>
          <div style="display: flex; margin-bottom: 20px; font-size: 14px">
            <div>Lange：</div>
            <div style="margin-left: 10%" @click="langeChange('中文')">中文</div>
            <div style="margin-left: 10%" @click="langeChange('English')">English</div>
          </div>
          <el-form-item prop="phoneCopy">
            <div style="display: flex">
              <div style="width: 280px">phone number：</div>
              <el-select v-model="formRegister.regionId" filterable placeholder="">
                <el-option
                  v-for="item in optionRegion"
                  :key="item.regionId"
                  :label="item.label"
                  :value="item.regionId"
                >
                </el-option>
              </el-select>
              <el-input v-model="formRegister.phoneCopy" placeholder=""></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <div style="display: flex">
              <div style="width: 225px">password：</div>
              <el-input
                v-model="formRegister.password"
                show-password
                placeholder=""
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="passwordOther">
            <div style="display: flex">
              <div style="width: 225px">Confirm password：</div>
              <el-input
                v-model="formRegister.passwordOther"
                show-password
                placeholder=""
              ></el-input>
            </div>
          </el-form-item>
          <!-- <el-form-item>
            <div style="display: flex">
              <div style="width: 130px">Lange</div>
              <el-select v-model="lange" filterable placeholder="" @change="langeChange">
                <el-option
                  v-for="item in optionsLange"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item> -->
          <el-form-item prop="customername">
            <div style="display: flex">
              <div style="width: 225px">Company Name：</div>
              <el-input v-model="formRegister.customername" placeholder=""></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="linkman">
            <div style="display: flex">
              <div style="width: 225px">Customer Contacts：</div>
              <el-input v-model="formRegister.linkman" placeholder=""></el-input>
            </div>
          </el-form-item>

          <!-- <el-form-item>
            <div style="display: flex">
              <div style="width: 180px">duty paragraph：</div>
              <el-input v-model="formRegister.taxOrIdentityID" placeholder=""></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <div style="display: flex">
              <div style="width: 180px">introducer：</div>
              <el-input v-model="formRegister.referee" placeholder=""></el-input>
            </div>
          </el-form-item> -->
          <!-- <el-form-item>
            <el-input v-model="form.username" placeholder="联系人"></el-input>
          </el-form-item> -->
        </el-form>
        <!--  -->
        <div
          style="
            width: 100%;
            justify-content: center;
            margin-top: 20px;
            display: flex;
            height: 50px;
          "
        >
          <div
            class="counter1"
            style="
              font-weight: 500;
              background: black;
              color: white;
              text-align: center;
              justify-content: center;
            "
            @click="formRegisterClick"
          >
            <div v-if="lange == '中文'">注册</div>

            <div v-else>Register</div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCaptchaImage } from "@/api/other/index.js";
import { getSaveCustomerMsg, getQueryRegion, register } from "@/api/auth";
export default {
  name: "",
  computed: {
    backGroungStyle() {
      return {
        background:
          this.brandName == "CHRISBELLA"
            ? "#a5ced0"
            : this.brandName == "SUSEN零售"
            ? "#f9e5cd"
            : "#ffcccb",
      };
    },
    ...mapGetters({
      lange: "user/lange",
      organizationName: "user/organizationName",
      priceChangeList: "user/priceChangeList",
    }),
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.formRegister.passwordOther !== "") {
          this.$refs.ruleForm.validateField("passwordOther");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formRegister.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === " ") {
        callback(new Error("请输入手机号码"));
      } else {
        callback();
      }
    };
    var validatePass4 = (rule, value, callback) => {
      if (value === " ") {
        callback(new Error("请输入公司名称"));
      } else {
        callback();
      }
    };
    var validatePass5 = (rule, value, callback) => {
      if (value === " ") {
        callback(new Error("请输入客户联系人"));
      } else {
        callback();
      }
    };
    var validatePass6 = (rule, value, callback) => {
      if (value === " ") {
        callback(new Error("请选择所属国家"));
        console.log(1);
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        password: "",
        passwordOther: "",
        phoneCopy: "",
        customername: "",
        linkman: "",
        regionId: "",
      },
      rules: {
        password: [{ required: true, validator: validatePass, trigger: "blur" }],
        passwordOther: [{ required: true, validator: validatePass2, trigger: "blur" }],
        phoneCopy: [{ required: true, validator: validatePass3, trigger: "blur" }],
        customername: [{ required: true, validator: validatePass4, trigger: "blur" }],
        linkman: [{ required: true, validator: validatePass5, trigger: "blur" }],
        regionId: [{ required: true, validator: validatePass6, trigger: "blur" }],
      },
      dialogFormVisible: false,
      dialogFormVisibles: false,
      form: {},
      formRegister: {},
      images: "",
      imagesOther: "",
      optionRegion: [],
      options: [
        {
          value: "广州",
          label: "广州",
        },
        {
          value: "迪拜",
          label: "迪拜",
        },
      ],
      optionsEnglish: [
        {
          value: "广州",
          label: "Guangzhou",
        },
        {
          value: "迪拜",
          label: "Dubai",
        },
      ],
      optionsLange: [
        {
          value: "中文",
          label: "中文",
        },
        {
          value: "English",
          label: "English",
        },
      ],
      loading: true,
      brandName: "",
    };
  },
  created() {},
  mounted() {
    console.log(this.priceChangeList);
  },
  watch: {},
  methods: {
    langeChange(event) {
      localStorage.setItem("lange", event);
      this.$store.dispatch("user/setLange", event);
    },
    selectChange(event) {
      this.$store.dispatch("user/setOrganizationName", event);
      localStorage.setItem("organizationName", event);
    },
    formRegisterClick() {
      if (this.loading == false) {
        this.$notify({
          title: this.lange == "中文" ? "错误操作" : "Error",
          message:
            this.lange == "中文"
              ? "正在注册中请勿重复点击按钮"
              : "Please do not click the button again while registering",
          type: "error",
          duration: 0,
          offset: 100,
        });
      } else {
        this.loading = false;

        let quhao = this.optionRegion.filter((item) => {
          return item.regionid == this.formRegister.regionId;
        })[0];
        if (quhao) {
          this.formRegister.phone = "00" + quhao.phoneCode + this.formRegister.phoneCopy;
        }
        console.log(quhao);
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            register(this.formRegister).then((res) => {
              if (res.code == "200") {
                this.$notify({
                  title: "成功",
                  message: "已成功注册，注册通过后将会发送短信给您！",
                  type: "success",
                  duration: 0,
                  offset: 100,
                });
                this.dialogFormVisible = false;
                this.dialogFormVisibles = false;
                this.loading = true;
              } else {
                this.loading = true;
              }
            });
          } else {
            this.$notify({
              title: this.lange == "中文" ? "错误操作" : "Error",
              message:
                this.lange == "中文"
                  ? "请将信息填写完整"
                  : "Please fill in the information completely",
              type: "error",
              duration: 0,
              offset: 100,
            });
            console.log("error submit!!");
            this.loading = true;
            return false;
          }
        });
      }
    },
    registerHuman() {
      this.dialogFormVisibles = true;
      getQueryRegion({}).then((res) => {
        this.optionRegion = res.data.list;
        this.optionRegion.forEach((item) => {
          item.label =
            "00" +
            item.phoneCode +
            "-" +
            item.regionsimplechinesename +
            "-" +
            item.regionname;
        });
      });
    },
    buyGoods(item) {
      if (item == 1) {
        this.form.brandName = "CHRISBELLA";
        this.brandName = "CHRISBELLA";
      } else if (item == 2) {
        this.brandName = "SUSEN零售";
        this.form.brandName = "SUSEN零售";
      } else {
        this.brandName = "BAGCO";
        this.form.brandName = "BAGCO";
      }
      // if (this.organizationName) {
      //   let organizationNameList = this.organizationName.split(",");
      //   let organizationNameOther = "";
      //   if (organizationNameList.length == 1) {
      //     organizationNameOther = organizationNameList[0];
      //   } else {
      //     organizationNameOther = "广州";
      //   }
      //   getSaveCustomerMsg({
      //     brandName: this.form.brandName,
      //     organizationName: organizationNameOther,
      //   }).then((res) => {
      //     console.log(res);
      //     if (res.code == 200) {
      //       localStorage.setItem("brandName", this.form.brandName);
      //       window.open(this.$locationViewUrl + "#/goods/GoodsHome", "_blank");
      //     } else {
      //       this.dialogFormVisible = true;
      //       getCaptchaImage().then((res) => {
      //         if (res.code == 200) {
      //           this.images = "data:image/gif;base64," + res.data.img;
      //           console.log(this.images);
      //           this.form.uuid = res.data.uuid;
      //           localStorage.setItem("uuid", res.data.uuid);
      //           getQueryRegion({}).then((res) => {
      //             this.optionRegion = res.data.list;
      //             this.optionRegion.forEach((item) => {
      //               item.label =
      //                 "00" +
      //                 item.phoneCode +
      //                 "-" +
      //                 item.regionsimplechinesename +
      //                 "-" +
      //                 item.regionname;
      //             });
      //           });
      //         }
      //       });
      //     }
      //   });
      // } else {
      getSaveCustomerMsg({
        brandName: this.form.brandName,
        // organizationName: this.form.organizationName,
      }).then((res) => {
        console.log(res);

        if (res.code == 200) {
          this.$store.dispatch(
            "user/setPriceChangeList",
            JSON.stringify(res.data.customerpriceadjustments)
          );
          localStorage.setItem(
            "priceChangeList",
            JSON.stringify(res.data.customerpriceadjustments)
          );
          localStorage.setItem("brandName", this.form.brandName);
          window.open(
            this.$locationViewUrl + "#/goods/GoodsHome?brandName=" + this.brandName,
            "_blank"
          );
        } else {
          this.dialogFormVisible = true;
          getCaptchaImage().then((res) => {
            if (res.code == 200) {
              this.images = "data:image/gif;base64," + res.data.img;
              console.log(this.images);
              this.form.uuid = res.data.uuid;
              localStorage.setItem("uuid", res.data.uuid);
              getQueryRegion({}).then((res) => {
                this.optionRegion = res.data.list;
                this.optionRegion.forEach((item) => {
                  item.label =
                    "00" +
                    item.phoneCode +
                    "-" +
                    item.regionsimplechinesename +
                    "-" +
                    item.regionname;
                });
              });
            }
          });
        }
      });
      // }
    },
    getAdvertList() {
      getCaptchaImage().then((res) => {
        if (res.code == 200) {
          this.images = "data:image/gif;base64," + res.data.img;
          console.log(this.images);
          this.form.uuid = res.data.uuid;
          localStorage.setItem("uuid", res.data.uuid);
        }
      });
    },
    getAdvertListOther() {
      getCaptchaImage().then((res) => {
        if (res.code == 200) {
          this.imagesOther = "data:image/gif;base64," + res.data.img;
          console.log(this.images);
          this.form.uuid = res.data.uuid;
          localStorage.setItem("uuid", res.data.uuid);
        }
      });
    },
    handleLogin() {
      this.loading = true;
      let quhao = this.optionRegion.filter((item) => {
        return item.regionid == this.form.regionId;
      })[0];
      console.log(quhao);
      if (quhao) {
        this.form.username = "00" + quhao.phoneCode + this.form.usernameCopy;
      }
      this.$store
        .dispatch("user/login", this.form)
        .then(async () => {
          localStorage.setItem("loginAccount", this.form.username);
          this.Flag = localStorage.getItem("flag");
          console.log(this.redirect);
          getSaveCustomerMsg({
            brandName: this.form.brandName,
          }).then((res) => {
            if (res.code == 200) {
              localStorage.setItem("brandName", this.form.brandName);
              this.$store.dispatch(
                "user/setPriceChangeList",
                JSON.stringify(res.data.customerpriceadjustments)
              );
              localStorage.setItem(
                "priceChangeList",
                JSON.stringify(res.data.customerpriceadjustments)
              );
              localStorage.setItem("userId", res.data.userId);
                
              let organizationNameList = res.data.sellOrganization.split(",");
              if (organizationNameList.length != 1) {
                localStorage.setItem("organizationName", "['广州','迪拜']");
                localStorage.setItem("organizationName", "['广州','迪拜']");
                this.$store.dispatch("user/setOrganizationName", "['广州','迪拜']");
              } else {
                if (organizationNameList[0] == "1") {
                  localStorage.setItem("organizationName", "广州");
                  localStorage.setItem("organizationNames", "广州");
                  this.$store.dispatch("user/setOrganizationName", "广州");
                } else {
                  localStorage.setItem("organizationName", "迪拜");
                  localStorage.setItem("organizationNames", "迪拜");
                  this.$store.dispatch("user/setOrganizationName", "迪拜");
                }
              }
              window.open(
                this.$locationViewUrl + "#/goods/GoodsHome?brandName=" + this.brandName,
                "_blank"
              );
              this.dialogFormVisible = false;
            } else {
              this.$notify.error({
                title: "错误",
                message: res.msg,
              });
              this.form = {};
            }
          });
          // let routerPath =
          // this.redirect === "/404" || this.redirect === "/401" ? "/" : this.redirect;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#MallHomepage {
  height: 100%;
  .counter1 {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 70%;
    padding: 5px;
    border: 1px solid #8e8e8e;
    position: relative;
    line-height: 31px;
    border-radius: 40px;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* 将页面分为3列，每列宽度平均 */
    // grid-gap: 10px; /* 设置列之间的间距 */
    height: 100vh;
  }

  /* 样式化每列 */
  .column {
    background-color: #f0f0f0;

    text-align: center;
    height: 100%;
    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
